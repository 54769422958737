<!--
 * @Author: zhangsheng zhangsheng@whty.com.cn
 * @Date: 2022-12-07 16:19:36
 * @LastEditors: zhangsheng zhangsheng@whty.com.cn
 * @LastEditTime: 2023-01-09 15:55:26
 * @FilePath: \web\src\views\invoicing\modules\dialog\discount.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <Dialog ref="dialog" :config="config" :visible='visible' :append='true' :cancelDialog="cancelDialog" :confirmDialog='confirmDialogs' :isdeling='isdeling'>
        <el-form ref="mainForm" :rules="rules" :model="assistingData" label-width="8rem" class="form">
          <el-form-item :label='taxIncluded?"商品原金额(含税)":"商品原金额(不含税)"' prop='code' label-position="left">
              <el-input v-model="assistingData.amount" disabled class="filter-item"></el-input>
          </el-form-item>
          <el-form-item label='折扣行数' prop='line' label-position="left">
              <el-input-number v-model="assistingData.line" class="filter-item" :min="1" :max="assistingData.max" @change="changeLine"></el-input-number>
          </el-form-item>
          <el-form-item label='折扣率(%)' prop='rate' label-position="left">
              <el-input-number v-model="assistingData.rate" class="filter-item" :min="0" :max="100" :controls='false' @blur="changeRate"></el-input-number>
          </el-form-item>
          <el-form-item :label='taxIncluded?"折扣金额(含税)":" 折旧金额(不含税)"' prop='discount' label-position="left">
              <el-input v-model="assistingData.discount" disabled class="filter-item"></el-input>
          </el-form-item>
      </el-form>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog";
export default {
  props: {
    assistingData:{
      type: Object,
    },
    assistingList:{
      type: Array,
    },
    taxIncluded:{
      type: Boolean,
    },
    visible: {
      type: Boolean,
    },
    isdeling: {
      type: Boolean,
    },
    cancelDialog:{
      type: Function,
      default: () => {},
    },
    confirmDialog:{
      type: Function,
      default: () => {},
    }
  },
  components: {
    Dialog,
  },
  data() {
   const checkTax = (rule, value, callback) => {
      if (value === 0) {
        return callback(new Error("折扣率不为0，请重新输入"));
      } 
      callback();
    }
    return {
      config: {
        top: '20vh',
        width: '21rem',
        title: '提示',
        center: true,
        btnTxt: ['确定','取消'],
      },
      rules: {
        line: [
          { required: true, message: "请输入折扣行数" },
        ],
        
        rate: [{ required: true, message: '请输入折扣率'},{ validator: checkTax, trigger: 'blur' }],
      },
      storageList: [],
      changeArr:[],
    };
  },
  methods: {
    changeLine(value){
      // console.log( this.storageList, this.assistingList , value , '123123', this.assistingList.length - value);
      let lineCount = this.assistingList.length - value
      let changeArr = JSON.parse(JSON.stringify(this.assistingList))
      if(lineCount){
        for (let index = 0; index < lineCount; index++) {
          changeArr.shift();
        }
      }else{
        changeArr = this.storageList  // 还原数组
      }
      this.changeArr = changeArr
      console.log(changeArr,'changeArr');

      this.changeRate();
    },
    
    changeRate(){
      let totalPrice = 0;
      this.changeArr.map((item,index)=>{
        if(this.taxIncluded){
          totalPrice  += (item.je?item.je:0) * 1;
        }else{
          totalPrice  += (item.bhsje?item.bhsje:0) * 1;
        }
      })
      this.assistingData.amount = totalPrice.toFixed(2);
      this.assistingData.discount = (totalPrice * this.assistingData.rate / 100).toFixed(2)
      console.log('changeRate');
    },

    confirmDialogs(){  //提交
      this.$refs.mainForm.validate((valid) => {
          if (valid) {
              this.confirmDialog(this.assistingData,this.changeArr);
              this.isLoading = false;
          }
      });
    },
  },
  watch:{
    'visible':{
        handler(val, oldVal) {
          if(val){
            this.storageList = JSON.parse(JSON.stringify(this.assistingList)) 
            this.changeArr =  JSON.parse(JSON.stringify(this.assistingList)) 
            this.assistingData.line = 1;
            this.changeLine(1)
          }else{
            this.storageList= []
            this.changeArr = []
          }
        },
        deep: true,
        immediate: true
      },
  }
};
</script>

<style lang="less" scoped>
  .title{
    color: #FF0000;
    font-size: 16px;
  }
  .text{
    color: #333333;
    font-size: 14px;
    margin: 6px 0;
  }
  .filter-item{
    width: 100%;
  }
</style>
